.LoadingIcon {
    animation: pulse infinite 1.5s linear;
    transform: rotate(90deg);
    color: #E8417D
}

.Delay0 {
    animation-delay: 0s;
}

.Delay1 {
    animation-delay: 0.25s;
}

.Delay2 {
    animation-delay: 0.5s;
}

.Delay3 {
    animation-delay: 0.75s;
}

.Delay4 {
    animation-delay: 1s;
}

.Delay5 {
    animation-delay: 1.25s;
}

.Delay6 {
    animation-delay: 1.5s;
}

.Delay7 {
    animation-delay: 1.75s;
}

@keyframes pulse {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }

}