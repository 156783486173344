.swiper-slide {
    background: none !important;
}

.swiper-wrapper {
    margin: 8px;
}

.swiper-pagination{
    position: relative !important;
}

.swiper-pagination-bullet{
    background-color: #E91E63;
}

.swiper-pagination-bullet-active{
    background-color: #c2185a;
}